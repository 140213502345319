/* eslint-disable max-classes-per-file */
import * as React from 'react';
import * as debounce from 'lodash/debounce';
import type { Entities } from '@inwink/entities/entities';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { Popover } from '@inwink/modals/popover';
import type { States } from '@@services/services';
import type { IItemsPickerProps, IItemsPickerItem } from "./itemspicker.props";
import { InWinkInput } from '../../helpers';

interface IItemsPickerAdvancedProps extends IItemsPickerProps {
    i18nHelper?: Entities.i18nHelper;
    event?: States.IEventState;
    community?: States.ICommunityState;
    selectedItems?: IItemsPickerItem[];
}

interface IItemsPickerAdvancedState {
    showItems: boolean;
    inputfilter: string;
    filter: string;
}

@connectwith((state: States.IAppState) => {
    return {
        event: state.event,
        community: state.community
    };
})
@withI18nHelper()
export class ItemsPickerAdvanced extends React.Component<IItemsPickerAdvancedProps, IItemsPickerAdvancedState> {
    searchInput = React.createRef<HTMLInputElement>();

    popoverCtrl = React.createRef<Popover>();

    constructor(props) {
        super(props);
        this.setFilter = debounce(this.setFilter, 200);
        this.searchChanged = debounce(this.searchChanged, 400);
        this.state = {
            showItems: false,
            inputfilter: "",
            filter: ""
        };
    }

    componentDidUpdate(prevProps: Readonly<IItemsPickerAdvancedProps>) {
        if (prevProps.items?.length !== this.props.items?.length || 
            prevProps.selection?.length !== this.props.selection?.length) {
            this.setFilter();
        }
    }

    onSearchFocus = () => {
        this.setState({ showItems: true });
    };

    toggleItem = (item: IItemsPickerItem) => {
        const newselection = this.props.selection ? [...this.props.selection] : [];
        const selectionIdx = newselection.indexOf(item.id);
        if (selectionIdx >= 0) {
            newselection.splice(selectionIdx, 1);
        } else {
            newselection.push(item.id);
        }

        this.props.selectionChanged(newselection);
    };

    hide = () => {
        this.setState({ showItems: false, inputfilter: "", filter: "" }, () => this.searchChanged(""));
    };

    setFilter = () => {
        this.setState((prevState) => ({ filter: prevState.inputfilter }));
        this.popoverCtrl.current?.refresh();
    };

    searchChanged = (txt: string) => {
        this.props.searchChanged?.(txt);
    };

    updateFilter = (arg) => {
        const txt = arg.currentTarget.value;
        this.setState(({ inputfilter: txt, filter: txt }), () => {
            this.searchChanged(txt);
        });
    };

    render() {
        let items = null;

        if (this.props.selection && this.props.selection.length) {
            items = this.props.selection && this.props.selection.map((s) => {
                const item = this.props.selectedItems?.filter((it) => it.id === s)?.[0];
                if (item) {
                    const maxLabelSize = 25;
                    let itemLabel = item.label;
                    if (typeof itemLabel === "object") {
                        itemLabel = this.props.i18nHelper.translateBag(itemLabel);
                    }

                    itemLabel = itemLabel && itemLabel.length > maxLabelSize
                        ? itemLabel.slice(0, maxLabelSize) + "..." : itemLabel;
                    return <span className={"keywordbubble bloc-lightbg item-" + s} key={s}>
                        <span>{itemLabel}</span>
                        {!this.props.isReadonly &&
                        <span className="togglebtn clickable" onClick={() => this.toggleItem(item)}>
                            <i className="inwink-dialog-cancel" /></span>
                        }
                    </span>;
                }
                return null;
            });
        }

        let themeclass = "";
        if (this.props.event?.eventid) {
            themeclass = "event-" + this.props.event.eventid;
        } else if (this.props.community?.communityid) {
            themeclass = "community-" + this.props.community.communityid;
        }

        return <div className="multiselect advanced">
            <div className="multiselect-content">{items}</div>
            {!this.props.isReadonly && <>
                <div className="searchbox">
                    <InWinkInput
                        ref={this.searchInput}
                        type="search"
                        placeholder={this.props.i18nHelper.translate("actions.clicktoselect")}
                        value={this.state.inputfilter}
                        onFocus={this.onSearchFocus}
                        onChange={this.updateFilter}
                    />
                    <i className="inwink-search" />
                </div>
            </>}
            {
                !this.props.isReadonly && this.state.showItems && <>
                    <Popover
                        ref={this.popoverCtrl}
                        show={true}
                        parent={this.searchInput.current}
                        className={"itemspicker-items-popover " + themeclass}
                        onhide={this.hide}
                        autofocus={false}>
                        <ItemsPickerAdvancedPopover
                            {...this.props}
                            filter={this.state.filter}
                            toggleItem={this.toggleItem}
                        />
                    </Popover>
                </>
            }
        </div>;
    }
}

interface IItemsPickerAdvancedPopoverProps extends IItemsPickerProps {
    filter: string;
    toggleItem: (item: IItemsPickerItem) => void;
    i18nHelper?: Entities.i18nHelper;
}

interface IItemsPickerAdvancedPopoverState {
    items: IItemsPickerItem[];
}

class ItemsPickerAdvancedPopover extends React.PureComponent<IItemsPickerAdvancedPopoverProps, IItemsPickerAdvancedPopoverState> {
    constructor(props: IItemsPickerAdvancedPopoverProps) {
        super(props);
        this.state = {
            items: this.getItems(props)
        };
    }

    componentDidUpdate(prevProps: IItemsPickerAdvancedPopoverProps) {
        if (this.props.filter !== prevProps.filter || this.props.items?.length !== prevProps.items?.length) {
            this.setState({ items: this.getItems(this.props) });
        }
    }

    getItems(props: IItemsPickerAdvancedPopoverProps) {
        if (!props.filter) return props.items && props.items.filter((item) => !item.disablePick);

        const searchterm = props.filter.toLowerCase();
        return props.items.filter((item) => {
            return (!item.disablePick) && item.lowerCaseLabel && (item.lowerCaseLabel.indexOf(searchterm) >= 0);
        });
    }

    render() {
        let items = null;
        if (this.state.items && this.state.items.length) {
            items = this.state.items.map((item) => {
                const isSelected = this.props.selection.indexOf(item.id) >= 0;
                let itemLabel = item.label;
                if (typeof itemLabel === "object") {
                    itemLabel = this.props.i18nHelper.translateBag(itemLabel);
                }

                return <div
                    key={item.id}
                    className={"itemspicker-item clickable item-" + item.id + (isSelected ? " selected" : "")}
                    onClick={() => this.props.toggleItem(item)}
                >
                    <div className={"item-checkmark" + (isSelected ? " bloc-accent" : "")}><i className="inwink-checked" /></div>
                    <div className="item-label">{itemLabel}</div>
                </div>;
            });
        }

        return <div className="itemspicker-items-popover-items bloctheme">
            {items}
        </div>;
    }
}
