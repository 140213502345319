import * as React from 'react';
import { DynLabel } from '@inwink/i18n/dynlabel';
import { IItemsPickerProps, IItemsPickerItem } from "./itemspicker.props";

export class ItemsPickerSimple extends React.Component<IItemsPickerProps, any> {
    toggleItem = (item : IItemsPickerItem) => {
        const newselection = this.props.selection ? [...this.props.selection] : [];
        const selectionIdx = newselection.indexOf(item.id);
        if (selectionIdx >= 0) {
            newselection.splice(selectionIdx, 1);
        } else {
            newselection.push(item.id);
        }

        this.props.selectionChanged(newselection);
    };

    render() {
        const items = this.props.items && this.props.items.map((item) => {
            const ischecked = this.props.selection.indexOf(item.id) >= 0;
            if (!ischecked && item.disablePick) {
                return null;
            }
            const name = "itemspicker-" + this.props.pickername + "-" + item.id;
            let label = null;
            if (typeof item.label === "string") {
                label = <label htmlFor={name}>{item.label}</label>;
            } else if (typeof item.label === "object") {
                label = <DynLabel component="label" htmlFor={name} i18n={item.label} />;
            }

            return <div className={"val " + name} key={name}>
                <input
                    id={name}
                    type="checkbox"
                    autoComplete="off"
                    checked={ischecked}
                    onChange={() => this.toggleItem(item)}
                />
                {label}
            </div>;
        }).filter((item) => !!item);

        return <div className="multiselect simple bloc-lightborder">{items}</div>;
    }
}
