import { translate, translateBag } from '@inwink/i18n/utils/translate';
import { Toggle } from '@inwink/toggles/toggle';
import * as React from 'react';
import * as assign from 'lodash/assign';
import { DynLabel } from '../../services/i18nservice';
import type { IFilterProps } from "./definitions";

export class EntityFieldBoolFilter extends React.Component<IFilterProps, any> {
    filterChange = (arg) => {
        const res = {};
        const val = (arg.target as HTMLSelectElement).value;
        const key = this.props.entityField?.key || this.props.filterField.name;
        if (val === "true") res[key] = true;
        else if (val === "false") res[key] = false;
        else res[key] = val;

        const newfilter = assign({}, this.props.filters, res);

        if (this.props.filterChanged) {
            this.props.filterChanged({ field: this.props.entityField, val: newfilter });
        }
    };

    render() {
        const key = this.props.entityField?.key || this.props.filterField.name;
        const val = this.props.filters[key] != null
            ? this.props.filters[key]
            : "";
        if (this.props.filterField?.display?.type === "toggle") {
            return <div className={"filter filter-bool toggle field-" + key}>
                <DynLabel
                    component="label"
                    i18n={this.props.filterField.label ? this.props.filterField.label : this.props.entityField.labels}
                    className="fieldlabel clickable"
                    onClick={(arg) => {
                        arg.preventDefault();
                        const newfilters = assign({}, this.props.filters, {
                            [key]: !this.props.filters[key]
                        });
                        this.props.filterChanged({ field: this.props.entityField, val: newfilters });
                    }}
                />
                <Toggle
                    checked={!!this.props.filters[key]}
                    onChange={(newchecked) => {
                        const newfilters = assign({}, this.props.filters, {
                            [key]: newchecked
                        });
                        this.props.filterChanged({ field: this.props.entityField, val: newfilters });
                    }}
                />
            </div>;
        }

        const SelectComponent = "select" as any;
        return <div className="filter filter-bool">
            <DynLabel
                component="label"
                i18n={this.props.filterField.label ? this.props.filterField.label : this.props.entityField.labels}
                className="fieldlabel clickable"
            />
            <SelectComponent
                className="bloc-lightborder"
                autoComplete="off"
                onChange={this.filterChange}
                value={val}
            >
                <option key="-1" value="empty">{translateBag(this.props.i18n, this.props.filterField.defaultText)}</option>
                <option key="1" value="true">{translate(this.props.i18n, "boolean.yes")}</option>
                <option key="0" value="false">{translate(this.props.i18n, "boolean.no")}</option>
            </SelectComponent>
        </div>;
    }
}
