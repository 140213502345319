import * as React from 'react';
import * as assign from 'lodash/assign';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { translateBag } from '@inwink/i18n/utils/translate';
import { States } from '@@services/services';
import type { IEntityFilterProps } from "./definitions";
import { getExpectedPickerTypeFromItemsCount, ItemsPicker } from './itemspicker';
import type { IItemsPickerItem, IItemsPickerType } from "./itemspicker.props";

interface IEntityFieldEntityFilterState {
    expanded: boolean;
    items: IItemsPickerItem[];
    pickerType?: IItemsPickerType;
    selectedItems?: IItemsPickerItem[];
}

export interface IEntityFieldEntityFilterProps extends IEntityFilterProps {
    i18n?: States.i18nState;
    event?: States.IEventState;
    community?: States.ICommunityState;
    pickerType?: IItemsPickerType;
    selection?: string[];
}

@connectwith((state: States.IAppState) => {
    return {
        i18n: state.i18n,
        event: state.event,
        community: state.community
    };
})
export class EntityFieldEntityFilter extends React.Component<IEntityFieldEntityFilterProps, IEntityFieldEntityFilterState> {
    constructor(props: IEntityFieldEntityFilterProps) {
        super(props);
        this.state = {
            expanded: this.props.filterField.isExpanded ? this.props.filterField.isExpanded : false,
            items: null,
            pickerType: this.props.pickerType
        };
    }

    componentDidMount() {
        this.loadSelectedItems(this.props.getActiveFilters(this.props.filterField.name)).then(selectedItems => {
            this.loadItems("", selectedItems);
        });
    }

    loadItems(search?: string, selectedItems?: IItemsPickerItem[]) {
        this.props.entityFilterHandler?.getItems(this.props, search).then((items) => {
            const newState = { 
                items: items,
                selectedItems: this.state.selectedItems,
                pickerType: this.props.pickerType || this.state.pickerType || getExpectedPickerTypeFromItemsCount(items.length) 
            };
            
            if (selectedItems)
                newState.selectedItems = selectedItems;

            this.setState(newState);
        });
    }

    loadSelectedItems = (selection?: string[]) => {
        const sel = selection || this.props.selection;
        const props = {selection: sel, ...this.props};
        if (sel)
            return this.props.entityFilterHandler?.getItems(props, "");

        return Promise.resolve([]);
    };

    componentDidUpdate(prevprops: IEntityFieldEntityFilterProps) {
        if (prevprops.i18n !== this.props.i18n) {
            this.loadItems();
        }
    }

    onItemsChanged = (selection: string[]) => {
        const newfilter = assign({}, this.props.filters, {
            [this.props.filterField.name]: selection
        });

        if (selection.length === 0) {
            delete newfilter[this.props.filterField.name];
        }

        const selectedItems = selection?.length ? this.state.items?.filter((x) => selection.some((s) => s === x.id)) : [];
        this.setState({selectedItems: [...this.state.selectedItems, ...selectedItems]});

        if (this.props.filterChanged) this.props.filterChanged({ field: this.props.filterField, val: newfilter });
    };

    onSearchChanged = (search: string) => {
        this.loadItems(search);
    };

    toggleExpanded = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        this.setState((state) => {
            return {
                expanded: !state.expanded
            };
        });
    };

    render() {
        const classNames = [
            "filter",
            "expandable",
            "filter-entityselect",
            this.state.expanded ? "expanded" : "collapsed"
        ];

        if (this.props.filterField.name) {
            classNames.push("field-" + this.props.filterField.name);
        }
        const field = this.props.filterField;
        const label = translateBag(this.props.i18n, field.label);
        let itemsbloc;
        const existing = this.props.getActiveFilters(this.props.filterField.name);
        const isReadonly = (field as any).isReadOnly;


        if (this.state.expanded) {
            itemsbloc = <ItemsPicker
                i18n={this.props.i18n}
                items={this.state.items}
                selectedItems={this.state.selectedItems}
                pickername={"entityfilter" + field.name}
                selection={existing}
                selectionChanged={this.onItemsChanged}
                disableAutoClose={this.props.disableAutoClose}
                pickerType={this.state.pickerType}
                isReadonly={isReadonly}
                searchChanged={this.onSearchChanged}
            />;
        }

        let stateMark;
        if (existing.length) {
            stateMark = <span className="statemark bloc-accentbg">
                {existing.length}
            </span>;
        }

        return <div className={classNames.join(" ")}>
            <div className="fieldtoggle clickable" onClick={this.toggleExpanded}>
                <label className="fieldlabel clickable">
                    <span>{label}</span>
                    {(field as any).isMandatory ? <span className="requiredmark">*</span> : null}
                </label>
                <div className="fieldtogglestate clickable">
                    {stateMark}
                    <span className="status bloc-lighttext inwink-chevron-right" />
                </div>
            </div>
            <div className="multiselect-items">
                {itemsbloc}
            </div>
        </div>;
    }
}
